<script>
  import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Alert
    } from 'sveltestrap';

  export let data;
  export let open=false;
  export let id;
  export let key;
  const toggle = () => (open = !open);

  function closeDialog() { open=false; }
  
  
  async function downloadQR(qrUrl) {
	    const response = await fetch(qrUrl);
	    const blob = await response.blob();
	    const blobUrl = URL.createObjectURL(blob);

	    const link = document.createElement('a');
	    link.href = blobUrl;
	    link.download = "qr.png";
	    document.body.appendChild(link);
	    link.click();
	    document.body.removeChild(link);
	    URL.revokeObjectURL(blobUrl);
  }
</script>

<Modal isOpen={open} {toggle}>
   <ModalHeader {toggle}>Sdílet</ModalHeader>
   <ModalBody>
    {#await data}
    {:then data}
     <center>
       <!--<img src="https://chart.googleapis.com/chart?cht=qr&chl={encodeURIComponent(data.url+'/key/'+data.kod+'/')}&chs=200x200&choe=UTF-8" />-->
       <img src="/api/qr/?key={data.kod}" width="200" />
       <div class="pt-2"><a href="/api/qr/?key={data.kod}" on:click|preventDefault="{()=>downloadQR('/api/qr/?key={data.kod}')}" download>uložit QR</a></div>
       <br><br>
       {data.url}/key/{data.kod}/
     </center>
    {/await}
   </ModalBody>
   <ModalFooter>
     <Button color="secondary" on:click={()=>closeDialog()}>Zavřít</Button>
   </ModalFooter>
</Modal>

<style>

</style>
