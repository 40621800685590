<script>
  import { onMount } from 'svelte';
  import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Alert
    } from 'sveltestrap';
  
  export let open=false;
  export let zasilkovaOpen;
  export let zasilkovnaId;
  export let zasilkovnaInfo;
  export let zasilkovnaAPI;
  const toggle = () => (open = !open);

  let widgetContainer;
  //const apiKey = 'b9cf4ed825b292f7';

  function widgetCallback(selectedData) {
    //console.log('Vybraná data:', selectedData);
    if(selectedData) {
    	if(selectedData.id) {
    		zasilkovnaId=selectedData.id;
    		zasilkovaOpen=false;
    	}
    	if(selectedData.id) {
    		zasilkovnaInfo=selectedData.name;
    	}
    }
  }

  // Funkce pro dynamické načtení skriptu
  function loadScript(src) {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  }

  onMount(async () => {
    try {
      // Načteme knihovnu
      await loadScript('https://widget.packeta.com/v6/www/js/library.js');

      if (window.Packeta && window.Packeta.Widget) {
        Packeta.Widget.pick(zasilkovnaAPI, widgetCallback, {
          language: 'cs',
          // další volitelné parametry...
        }, widgetContainer);
      } else {
        console.error('Packeta knihovna nebyla načtena');
      }
    } catch (error) {
      console.error('Chyba při načítání Packeta skriptu:', error);
    }
  });
</script>

<Modal isOpen={open} {toggle} size="lg">
   <ModalHeader {toggle}>Výběr Zásilkovny</ModalHeader>
   <ModalBody>
     <div bind:this={widgetContainer}></div>
   </ModalBody>
</Modal>

