<script lang="ts">
 import { onMount } from 'svelte';
 import { Button, ButtonGroup } from 'sveltestrap';
  
 export let selected='';
 export let data;
 export let zasilkovaOpen;
 export let zasilkovnaInfo;
 let paymentOptions = [  ];


 function getTransportPrice(cena,dobirka,freeFrom) {
   let text="";
   if(freeFrom!="0" && data.cart) {
     let sum=0;
     for(let i=0; i<data.cart.length; i++) {
        sum+=parseInt(data.cart[i].price);
     }

     if(sum>parseInt(freeFrom)) { return ""; }
   }
   if(dobirka && dobirka!=-1) { text='('+cena+',- | s dobírkou: '+dobirka+',-)'; }
   else { text='('+cena+',-)'; }
   return text;
 }

onMount(async () => {
  if(data) {
    if(data.config.delivery) {
      paymentOptions = [  ];
      if(data.config.delivery.osobniodber && data.school.doprava!="doruceni") {
        let osobne = "Osobní odběr";
        if(data.config.text.osobne !== null && data.config.text.osobne!="") { osobne=data.config.text.osobne; }	//+' '+data.config.delivery.osobniodber_misto
        else if(data.config.delivery.osobniodber_misto!="") { osobne='Osobní odběr na adrese: '+data.config.delivery.osobniodber_misto; }
        paymentOptions.push (
           {
             title: osobne,
             value: 'osobne',
           }
        );
        if(selected=='') { selected='osobne'; }
      }
      if(data.config.delivery.zasilkovnacena && data.config.delivery.zasilkovnacena!="0" && data.config.delivery.zasilkovnacena!="-1" && data.school.doprava!="osobne") {
        paymentOptions.push (
           {
             title: 'Zásilkovna '+getTransportPrice(data.config.delivery.zasilkovnacena, data.config.delivery.zasilkovnadobirka, data.config.delivery.zasilkovnazdarma),
             value: 'zasilkovna',
           }
        );
        if(selected=='') { selected='zasilkovna'; }
      }
      if(data.config.delivery.kuryr && data.config.delivery.kuryrcena!="0" && data.config.delivery.kuryr!="false" && data.config.delivery.kuryrcena!="-1" && data.school.doprava!="osobne") {
        paymentOptions.push (
           {
             title: 'Kurýrem '+data.config.delivery.kuryr+' '+getTransportPrice(data.config.delivery.kuryrcena, data.config.delivery.kuryrdobirka, data.config.delivery.kuryrzdarma),
             value: 'kuryr',
           }
        );
        if(selected=='') { selected='kuryr'; }
      }

    }
  }
});

  function selectedButton(val) {
  	selected=val;
  }
</script>

{#await data}

{:then data}
 {#if data}
	<ButtonGroup vertical>
	  {#each paymentOptions as item}
	  	{#if item.value==selected}
	  		<Button on:click="{()=>selectedButton(item.value)}" color="primary">{@html item.title}</Button>
	  	{:else}
	  		<Button on:click="{()=>selectedButton(item.value)}" color="light">{@html item.title}</Button>
	  	{/if}
	  	{#if item.value=="zasilkovna" && zasilkovnaInfo}
	  		<br />
	  		<b>Vybrané místo: </b>{zasilkovnaInfo} <a href="#" on:click|preventDefault="{()=>zasilkovaOpen=true}">změnit</a>
	  	{/if}
	  {/each}
	</ButtonGroup>
 {/if}
{/await}

